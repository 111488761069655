<template>
  <div>
    <portal to="title-page">
      <h1>Zonas para transporte</h1>
    </portal>
    <v-data-table v-if="zones != null"
      v-model="selected"
      :headers="tableHeaders"
      :items="zones"
      item-key="id"
      :search="search"
      fixed-header
      class="elevation-1"
    >
      <template v-slot:top>
        <div class="table-top-bar">
          <v-row>
            <v-col cols="6" sm="6" md="6">
              <v-text-field
                v-model="search"
                prepend-icon="mdi-magnify"
                label="Buscar"
                dense
                hide-details
              ></v-text-field>
            </v-col>
            <v-col cols="6" sm="6" md="6">
              <v-btn @click="$router.push({path:'/backend/admin/zone/create'})" small color="primary"><v-icon>mdi-plus-thick</v-icon> Zona</v-btn>
            </v-col>
          </v-row>
        </div>
        <v-divider></v-divider>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-icon 
          class="mr-2"
          @click="$router.push({path:`/backend/admin/zone/${item.id}/edit`})"
        >
          mdi-pencil
        </v-icon>
        <v-icon class="mr-2" color="red" @click="deleteZone(item)">mdi-delete</v-icon>
      </template>
      <template v-slot:item.status="{ item }">
        <v-btn @click="setStatus(item)"
          small :color="item.status == 'active' ? 'green' : 'red' || this.itemIdStatus.status == 'inactive' ? 'red' : 'green'" rounded
          dark>
          <v-icon small>{{mdiPencil}}</v-icon>
          {{ item.status }}
        </v-btn>
      </template>
    </v-data-table>
    <v-snackbar
      v-model="snackbar.show"
      :color="snackbar.color"
    >
      {{snackbar.message}}
      <template v-slot:action="{ attrs }">
        <v-btn
          color=""
          text
          v-bind="attrs"
          @click="snackbar.show = false"
        >
          <v-icon>{{mdiClose}}</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
    <v-dialog v-if="itemIdStatus" v-model="dialogStatus" max-width="450px">
      <v-card >
        <v-card-title class="">Editar status de la zona {{itemIdStatus.name}}</v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-select
            v-model="itemIdStatus.status"
            :items="selectStatus"
            item-text="text"
            label="Status"
            item-value="type"
          ></v-select>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn  @click="closeDialogStatus()">Cerrar</v-btn>
          <v-btn color="primary" text @click="statusChange(itemIdStatus.status)">Guardar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
  import Zone from '@/models/Zone';
  import { mdiClose, mdiPencil } from '@mdi/js';
  export default {
    name:'Zone',
    data:() => {
      return {
        mdiPencil: mdiPencil,
        mdiClose:mdiClose,
        snackbar:{
          show:false,
          message:'',
          color:''
        },
        search:'',
        zoneModel : new Zone,
        zones : [],
        selected : [],
        tableHeaders:[
          {
            text: 'Zona',
            align: 'start',
            sortable: true,
            value: 'name',
          },
          {
            text: 'Tarifa privada',
            align: 'start',
            sortable: false,
            value: 'pricePrivate',
          },
          {
            text: 'Tarifa colectiva',
            align: 'start',
            sortable: false,
            value: 'priceShared',
          },
          {
            text: 'Status',
            align: 'start',
            sortable: false,
            value: 'status',
          },
          { text: 'Actions', value: 'actions', sortable: false },
        ],
        itemIdStatus: '',
        dialogStatus: false,
        statusZona: '',
        selectStatus: [
          { type: "active", text: "Active" },
          { type: "inactive", text: "Inactive" },
        ],
        validFormStatus:true
      }
    },
    methods:{
      deleteZone(item){
        this.$dialog
        .confirm(`¿Desea eliminar la zona <strong>${item.name}</strong>?`, {
          loader: true,
        })
        .then((dialog) =>{
          this.zoneModel.delete(item.id).then(() =>{
            dialog.close();
            this.snackbar.show = true;
            this.snackbar.color = "green";
            this.snackbar.message = `La zona se elimino correctamente`;
          })
          .catch((error) => {
            console.error("Error removing document: ", error);
            dialog.close();
            this.snackbar.show = true;
            this.snackbar.color = "red";
            this.snackbar.message = `Error al eliminar la zona, por favor contacte al Administrador si el problema persiste`;
          });
        })
      },
      statusChange(itemStatus) {
        let loading = this.$loading.show();
        this.zoneModel
          .update(this.itemIdStatus.id, { status: itemStatus })
          .then(() => {
            loading.hide();
            this.snackbar.show = true;
            this.snackbar.color = "green";
            this.snackbar.message = `El estatus de la zona se actualizo correctamente`;
            this.dialogStatus = false;
          })
          .catch((error) => {
            console.error("Error removing document: ", error);
            this.snackbar.show = true;
            this.snackbar.color = "red";
            this.snackbar.message = `Error al cambiar el status de la zona, por favor contacte al Administrador si el problema persiste`;
          });
      },
      setStatus(item) {
        this.itemIdStatus = {... item};
        this.dialogStatus = true;
      },
      closeDialogStatus() {
      this.dialogStatus = false;
      },
    },
    mounted() {
      this.zoneModel.getAll().onSnapshot(qSnap => {
        this.zones = [];
        qSnap.forEach(doc => {
          let zon = doc.data();
          zon.id = doc.id;
          this.zones.push(zon)
        })
      })
    },
    created () {
      this.$store.commit('SET_LAYOUT', 'admin')
    }
  }
</script>
<style>
</style>